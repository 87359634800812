import React from 'react';
import { Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {userNameEmpId} from '../userDetails'

const items = [
  {
    key: '1',
    label: (
      <p className="dropdown-item">
        {userNameEmpId.name}
      </p>
    ),
  },
  {
    key: '2',
    label: (
      <p className="dropdown-item">
        {userNameEmpId.empId}
      </p>
    ),
  },
];

const User = () => {
  return (
    <span className="dropdown-item">
      <Dropdown
        menu={{
          items,
        }}
        placement="bottom"
      >
          <UserOutlined style={{ color: '#fff', fontSize:"20px" }} />
      </Dropdown>
    </span>
  );
};

export default User;
