import React, { useEffect, useState } from "react";
import "../Styles/DashboardStyle.css";
import { Flex, Statistic, Row, Col, Select, Spin, Popover } from "antd";
import CountUp from "react-countup";
import { employeeTimesheet } from "../api";
import { employeeId } from "../utils/userDetails";
import moment from "moment";
import { useNavigate } from 'react-router-dom';

const formatter = (value) => {
  return value > 0 ? <CountUp end={value} separator="," /> : "-";
};

const chunkArray = (arr, chunkSize) => {
  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
};

const Dashboard = () => {
  const monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [dashboardData, setDashboardData] = useState([]);
  const currentDate = moment();
  const [currentMonth, setCurrentMonth] = useState(currentDate.month() + 1);
  const [currentYear, setCurrentYear] = useState(currentDate.year());
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); 

  useEffect(() => {
    fetchData();
  }, [currentMonth, currentYear]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await employeeTimesheet.approverDashboard({
        employeeNo: employeeId,
        year: currentYear,
        month: currentMonth,
      });
      setDashboardData(response.projects);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleMonthChange = (value) => {
    setCurrentMonth(value);
  };

  const handleYearChange = (value) => {
    setCurrentYear(value);
  };

  const projectRows = chunkArray(dashboardData, 4);

  const renderEmployees = (employees) => (
    <ul style={{ padding: "0 15px"}}>
      {employees.length > 0
        ? employees.map((employee, index) => (
            <li key={index}>{employee.name}</li>
          ))
        : "No employees"}
    </ul>
  );

  const handleProjectClick = (projectId) => {
    navigate("/timesheet-approval", { state: { projectId } });
  };

  return (
    <>
      {loading ? (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Spin size="large" />
        </span>
      ) : (
        <Row style={{ height: "100%" }}>
          <Col span={24}>
            <Flex vertical gap={16} className="tiles">
              <Flex justify="space-between">
                <span className="tileHeading">Dashboard</span>
                <span style={{ display: "flex", gap: "10px" }}>
                  <div>
                    <div className="selectDropDown">Month</div>
                    <Select
                      placeholder="Select Month"
                      defaultValue={currentMonth}
                      onChange={handleMonthChange}
                      size="large"
                      style={{ width: "120px" }}
                    >
                      {monthArray.map((month, index) => (
                        <Select.Option key={index} value={index + 1}>
                          {month}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                  <div>
                    <div className="selectDropDown">Year</div>
                    <Select
                      placeholder="Select Year"
                      defaultValue={currentYear}
                      onChange={handleYearChange}
                      size="large"
                      style={{ width: "100px" }}
                    >
                      {Array.from(
                        { length: 10 },
                        (_, i) => currentDate.year() - 5 + i
                      ).map((year) => (
                        <Select.Option key={year} value={year}>
                          {year}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </span>
              </Flex>

              {projectRows ? projectRows.map((projectRow, rowIndex) => (
                <Flex key={rowIndex} gap={16} style={{ marginBottom: "16px" }}>
                  {projectRow.map((project, index) => (
                    <Flex
                      className="subTiles"
                      style={{
                        width: "24%",
                      }}
                      gap={10}
                      vertical
                      key={index}
                    >
                      <Flex justify="space-between" className="subFooterDiv">
                        <span className="subHeading" 
                          onClick={() => handleProjectClick(project.projectId)}
                          style={{ cursor: "pointer" }}
                        >
                          <b>{project.projectName}</b>
                        </span>
                        <div className="subFooterDiv1">
                          <Flex vertical align="start">
                            <Popover
                              content={renderEmployees(project.draft)}
                              title="Draft Employees"
                            >
                              <div style={{ cursor: "pointer" }}>
                                <Statistic
                                  className="count"
                                  valueStyle={{ color: "#1677ff" }}
                                  value={project.status.draft}
                                  formatter={formatter}
                                />
                              </div>
                            </Popover>
                            <span className="counthead">Draft</span>
                          </Flex>

                          <Flex vertical align="start">
                            <Popover
                              content={renderEmployees(project.pendingApproval)}
                              title="Pending Approval Employees"
                            >
                              <div style={{ cursor: "pointer" }}>
                                <Statistic
                                  className="count"
                                  valueStyle={{ color: "#f5222d" }}
                                  value={project.status.pendingApproval}
                                  formatter={formatter}
                                />
                              </div>
                            </Popover>
                            <span className="counthead">Pending Approval</span>
                          </Flex>
                        </div>

                        <div className="subFooterDiv2">
                          <Flex vertical align="start">
                            <Popover
                              content={renderEmployees(project.approved)}
                              title="Approved Employees"
                            >
                              <div style={{ cursor: "pointer" }}>
                                <Statistic
                                  className="count"
                                  valueStyle={{ color: "#faad14" }}
                                  value={project.status.approved}
                                  formatter={formatter}
                                />
                              </div>
                            </Popover>
                            <span className="counthead">Approved</span>
                          </Flex>

                          <Flex vertical align="start">
                            <Popover
                              content={renderEmployees(project.rejected)}
                              title="Rejected Employees"
                            >
                              <div style={{ cursor: "pointer" }}>
                                <Statistic
                                  className="count"
                                  valueStyle={{ color: "#ffd666" }}
                                  value={project.status.rejected}
                                  formatter={formatter}
                                />
                              </div>
                            </Popover>
                            <span className="counthead">Rejected</span>
                          </Flex>
                        </div>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              )) 
              : 
              <span style={{ fontSize: "20px", fontWeight: "bold", marginTop: "100px" }}>No project found</span>
              }
            </Flex>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Dashboard;
