import { EditOutlined,EyeOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Modal, Row, Space, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { IoCheckmarkOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import "../Styles/Form.css";
import { linkEmployees } from '../api';
import AddNewTimeSheetForm from '../components/Form/AddNewTimeSheetForm';
import TableComponent from '../utils/TableComponent';
import { useNavigate } from 'react-router-dom';

const AddNewTimeSheet = () => {
  const {Title}=Typography
  const navigate=useNavigate()
    const [loading, setLoading] = useState(false);
    const [resources, setResources] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [islinked, setIsLinked] = useState(false);
    const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 10,
      total: 0
    });

    const columns = [
        { title: "Project",
          dataIndex: "projectName",
          key: "projectName",
          change: true,
          fixed: 'left',
          width: "15%",
        },
        { title: "Internal",
          dataIndex: "isInternal",
          key: "isInternal",
          width: "10%",
          render: (text, record) => {
            return !text ? <RxCross1 style={{ margin: "0 0 0 1rem", color: "#df0b2e", fontSize: "16px" }} /> : <IoCheckmarkOutline style={{ margin: "0 0 0 1rem", color: "green", fontSize: "18px" }} />
          },
        },
        { title: "Delivery Manager",
          dataIndex: "deliveryManager",
          key: "deliveryManager",
          change: true,
          width: "20%",
        },
        { title: "Product Manager",
          dataIndex: "productManager",
          key: "productManager",
          change: true,
          width: "20%",
        },
        { title: "Current Employees",
          dataIndex: "employees",
          key: "employees",
          width: "25%",
          render: (text, record) => {
            const currentDate = new Date();
            
            const employees = [...new Set(
              text?.filter(({ isActive, endDate }) => 
                isActive || (endDate === null || new Date(endDate) > currentDate)
              ).map(({ name }) => name)
            )];
        
            return employees.length ? employees.join(", ") : "No current employees";
          }
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          width: "10%",
          render: (_, record) => (
            <Space size="middle" style={{display:"flex",gap:"2rem"}}>
              <Tooltip title="Edit" arrow={false}>
                <a onClick={() => handleEdit(record)}>
                  <EditOutlined style={{ fontSize: "15px" }} />
                </a>
              </Tooltip>
              <Tooltip  title="Employee History" color="8e8e8e" >
              <a
                onClick={() => {
                  navigate("/employee-history", {
                    state:{
                      projectId:record.projectId
                    }
                  });
                }}
                style={{ fontSize: "16px", textDecoration: "none" }}
              >
                <EyeOutlined />
              </a>
            </Tooltip>
            </Space>
          )
        }
    ];

    const handleEdit = (record) => {
        setSelectedProject(record);
        setIsModalOpen(true);
    };

    const handleTableChange = (pagination) => {
        setPagination({
          ...pagination,
          total: resources.length
        });
    }
    const handleModalClose = () => {
      setIsModalOpen(false);
      setSelectedProject(null);
  };

    const getLinkedEmployeeTable = async () => {
      try {
        setLoading(true);
        const response = await linkEmployees.getLinkedEmployee();
        if (response && Array.isArray(response)) {
          setResources(response);
        }
      } catch (error) {
        console.error('Failed to fetch linked employees:', error);
      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      getLinkedEmployeeTable();
    }, []);

    useEffect(() => {
      if (islinked) {
        getLinkedEmployeeTable();
        setIsLinked(false);
      }
    }, [islinked]);

    return (
      <div style={{ padding: "1.5rem 0 2rem 0" }}>
        <Row justify="start" align="middle">
          <Col>
            <Title level={3} style={{ margin: "0",padding:"0 0 0 1rem" }}>
              Linked Employee Details
            </Title>
          </Col>
        </Row>
        <Flex vertical gap="2rem" style={{ margin: "0 1rem" }}>
          <Button
            type="primary"
            size="large"
            onClick={() => { 
              setIsModalOpen(true);
              setSelectedProject(null);
            }}
            style={{ width: "fit-content", margin: "0 1rem", alignSelf: 'end' }}
          >
            Link Employee
          </Button>
          <TableComponent
            loading={loading}
            rows={resources}
            columns={columns}
            // scrollx={1400}
            pagination={pagination}
            onChange={handleTableChange}
            locale={{ emptyText: "No data available for given input" }}
          />
        </Flex>
        <Modal
        
          title={<div style={{ textAlign: "center", fontSize: "1.5rem" }}>Link Employees</div>}
          open={isModalOpen }
          onCancel={handleModalClose}
          footer={null}
          width={630}
          className="timesheetModal"
        >
          <AddNewTimeSheetForm
            hide={setIsModalOpen}
            linkedEmp={setIsLinked}
            Open={isModalOpen}
            selectedNewProject={selectedProject}
          />
        </Modal>
      </div>
    )
}

export default AddNewTimeSheet;
